<template>
  <v-app>
    <Header v-if="!isPrint"/>
    <div>
      <router-view></router-view>
    </div>
  </v-app>
</template>


<script>


import Header from '@/components/PageHeader.vue'
import {currentUserStore} from './stores/currentUserStore'

import api from './api'

export default {
  name: 'App',
  components: {
    // Footer,
    Header
  },
  data() {
    return {
      user: null,
      isPrint: false,
    }
  },
  created() {
    this.$watch(
        () => this.$route.name,
        () => {
          this.isPrint = this.$route.name === "planviewprint";
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
    
  },
  mounted() {
    this.isPrint = this.$route.name === "planviewprint";

    api.me.get(response => {
          currentUserStore().currentUser = response
        },
        err => {
          if (this.$route.name !== "sharedview") {
            window.location = "/login"
          }

        })
  },

}
</script>